<template>
  <v-card>
    <!-- file input -->
    <input
      ref="uploader"
      class="d-none"
      type="file"
      multiple
      accept=".pdf"
      @change="onFileChanged"
    />
    <!-- toolbar -->
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        @click="foliate"
        v-if="items.length > 0"
        >{{ $t('operations.foliate') }}
      </v-btn>
      <v-btn
        color="blue-grey lighten-2 dark"
        class="mx-1"
        dark
        v-if="items.length > 0"
        @click="clearItems"
        >{{ $t('operations.clear') }}
      </v-btn>
      <v-btn
        color="teal dark"
        dark
        class="mx-1"
        @click="addItem"
        :loading="isSelecting"
      >
        {{ $t('operations.add_files') }}
      </v-btn>
    </v-toolbar>

    <!-- table -->
    <v-data-table
      v-if="items.length > 0"
      v-model="selected"
      :headers="headers"
      :items="items"
      :single-select="false"
      :hide-default-footer="true"
      :disable-pagination="true"
      :loading="loading"
      sort-by="id"
    >
      <template v-slot:loading>{{ $t('operations.loading') }} </template>
      <template v-slot:[`item.id`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.options`]="{ index }">
        <div>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="deleteItem(index)"
            >{{ $t('operations.delete') }}
          </v-btn>

          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="moveItem(index, true)"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>

          <v-btn
            color="blue-grey lighten-2 dark"
            class="mx-1"
            dark
            small
            @click="moveItem(index, false)"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <!-- form -->
    <AppDialogForm ref="dialogForm" :isValid="isValid">
      <v-form ref="form" lazy-validation v-model="isValid">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="folio"
              type="number"
              :rules="formRules"
              autocomplete="off"
              required
            >
              <template v-slot:label>{{ $t('fields.start_folio') }} </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="filename"
              :rules="formRules"
              autocomplete="off"
              required
              autofocus
            >
              <template v-slot:label>{{ $t('fields.file_name') }} </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </AppDialogForm>
    <!-- confirm -->
    <AppDialogConfirm ref="confirm" />
  </v-card>
</template>

<script>
import AppDialogConfirm from '@/components/dialogs/Confirm.vue'
import AppDialogForm from '@/components/dialogs/Form.vue'
export default {
  name: 'Breeders',
  components: { AppDialogConfirm, AppDialogForm },
  data: () => ({
    name: null,
    url: null,
    headers: [],
    formRules: [(v) => !!v || 'Required'],
    items: [],
    selected: [],
    folio: null,
    filename: null,
    loading: false,
    loading_text: null,
    isSelecting: false,
    isValid: false
  }),

  created() {
    this.url = 'services/foliation/'
    this.name = this.$t('options.services.foliation')
    this.headers = [
      { text: 'Id', value: 'id' },
      { text: this.$t('fields.name'), value: 'name' },
      { text: this.$t('fields.size'), value: 'size' },
      { text: this.$t('fields.options'), value: 'options', align: 'right' }
    ]
  },

  methods: {
    moveItem(index, up) {
      var to = up ? index - 1 : index + 1
      this.items.splice(to, 0, this.items.splice(index, 1)[0])
    },

    deleteItem(index) {
      let options = { color: 'teal' }
      let title = this.$t('operations.delete').toUpperCase()
      let message = this.$t('dialogs.confirm_text')
      this.$refs.confirm.open(title, message, options).then((confirm) => {
        if (confirm) this.items.splice(index, 1)
      })
    },

    addItem() {
      this.isSelecting = true
      window.addEventListener('focus', () => (this.isSelecting = false), {
        once: true
      })
      this.$refs.uploader.click()
    },

    clearItems() {
      this.items = []
    },

    onFileChanged(e) {
      for (var x = 0, i; (i = e.target.files[x]); x++) {
        let size = parseFloat(i.size / (1024 * 1024)).toFixed(2)
        this.items.push({
          name: i.name,
          size: `${size} MB`,
          file: i
        })
      }
      this.$refs.uploader.value = null
    },

    foliate() {
      this.folio = 1
      this.filename = this.$t('operations.foliated_file').toUpperCase()
      let options = { color: 'teal' }
      let title = this.$t('operations.foliate').toUpperCase()
      this.$refs.dialogForm.open(title, options).then((confirm) => {
        if (confirm && this.$refs.form.validate()) {
          const data = new FormData()
          data.append('folio', this.folio)
          this.items.forEach((e) => {
            data.append('file', e.file)
          })

          this.$http
            .post(this.url, data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: 'application/pdf'
              })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `${this.filename}.pdf`
              link.click()
              URL.revokeObjectURL(link.href)
              this.items = []
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    }
  }
}
</script>

<style></style>
